import { backendUrl } from "./fetchUtils";

export const getCarLogo = (carBrand, height) => {
  let brand = carBrand?.toLowerCase().replace(/ /g, "");
  if (brand === undefined) return;

  if (brand === "vw") brand = "volkswagen";
  let path = `https://vehapi.com/img/car-logos/${brand}.png`;

  if (brand === 'bmwvag')
   {
    path =  `${backendUrl()}/car_logos/bmwvag.png`;
    height = height + 20;
   }

  return (
    <img
      className="img-fluid"
      style={{ height: height + "px" }}
      src={path}
      alt=""
    ></img>
  );
};
