import React, { useState, useEffect } from "react";
import Select from "react-select";

export const Selector = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(props.clearSelectedAfterPick === undefined ? option : {});
    props.handleChange(props.isMulti ? option : option.value);
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      borderColor: "orange",
      boxShadow: null,
      "&:hover": {
        boxShadow: null,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      padding: 10,
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 1500 }),
  };

 
  useEffect(() => {
    if (props.options === undefined) return;
    setDefValue();
  }, [props.options]);

  useEffect(() => {
    if (props.value !== undefined) {
      setDefValue(); 
    }
  }, [props.value]);

  const setDefValue = () => {
    if (!props.options) return;
    
    if (props.isMulti) {
      setMultiDefValue();
    } else {
      const defaultValue = props.options.find((option) =>
        props.value !== undefined ? option.value === props.value : option.defValue
      );
      setSelectedOption(defaultValue || props.options[0] || null);
    }
  };

  const setMultiDefValue = () => {
    const defaultValues = props.options.filter((option) =>
      props.value ? props.value.includes(option.value) : option.defValue
    );
    setSelectedOption(defaultValues);
  };

  return (
    <>
      <div className="form-group py-0">
        {props.label && (
          <span
            className="font14 input-group-text py-0 justify-content-center"
            id=""
          >
            {props.label}
          </span>
        )}
        {/* <p className="input-label">{props.label}</p> */}
        <Select
          isMulti={props.isMulti}
          aria-label="select-label"
          classNamePrefix="react-select"
          className={"select py-0"}
          value={selectedOption}
          onChange={(e) => handleChange(e)}
          options={props.options}
          styles={customStyles}
          placeholder={props.placeholder}
          isDisabled={props.disabled || props.isLoading}
          isLoading={props.isLoading}
          isSearchable={props.searchable || false}
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          maxMenuHeight={1000}
        />
      </div>
    </>
  );
};
